<script lang="ts" setup>
import { useTgCustomer } from '~/hooks/useTgCustomer'
import { useUserInfo } from '~/composables/app'
import { hasToken } from '~/utils'
import { useSwapShow } from '~/composables/app/useSwapShow'

const { enableExchange } = useSwapShow()
const visible = ref(false)
const isLogout = ref(false)
const tgLink = useTgCustomer()
const { userInfo, clearInfo } = useUserInfo()
function handleLogout() {
  visible.value = false
  isLogout.value = true
}

const loadLogout = ref(false)
async function logout() {
  if (hasToken()) {
    try {
      loadLogout.value = true
      await apis.authLogout(undefined)
      clearInfo()
      loadLogout.value = false
      aMessage.success($t('zvpQimqzEbDu2UpIa73yP'))
      isLogout.value = false
    }
    catch {
      console.log('注销Token失败')
      loadLogout.value = false
    }
  }
}
</script>

<template>
  <div class="side-nav-wrapper">
    <div class="open-btn text-left p-[5px] pl0" data-test-id="ac14" @click="visible = true">
      <i class="icon i-fluent-mdl2:numbered-list-text text-[22px]" />
    </div>
    <ClientOnly>
      <ADrawer v-model:open="visible" rootClassName="side-nav-drawer" :closable="false" placement="left">
        <ul class="nav-list" data-test-id="4026" @click="visible = false">
          <li class="item log">
            <div class="link">
              <i class="icon i-local:logo-1" />
              <span class="title">Feee.io</span>
            </div>
          </li>
          <li v-if="userInfo" class="mb-10px">
            <a class="info relative" :href="`/console?lang=${$t('console-lang')}`" data-test-id="dCLJ">
              <div>
                <i class="icon i-local:user?mask !text-[#e2e8f2] mr-0.2em" />
                <span class="text !text-14px w-70% overflow-hidden text-ellipsis whitespace-nowrap">
                  {{ userInfo?.account }}
                </span>
              </div>
              <div>
                <i class="icon i-local:pocket?mask !text-[#e2e8f2] mr-0.2em" />
                <span class="text !text-14px">{{ formatUserAmount(userInfo?.trx_money) }} TRX</span>
              </div>
              <i class="i-material-symbols:arrow-forward-ios text-white absolute top-1/2 translate-y--50% right-2" />
            </a>
          </li>
          <li v-else class="login">
            <a :href="`/console?lang=${$t('console-lang')}`" data-test-id="xdtl">{{ $t('vTL9tlsWzbAAgQHYbEYMq') }}</a>
          </li>
          <li class="item">
            <NuxtLink class="link" :to="localePath('/')">
              <i class="icon i-local:home-s1?mask" />
              <span class="text">{{ $t('tXRmdyXSXwjXI1ckkd-W4') }}</span>
            </NuxtLink>
          </li>
          <li class="item">
            <NuxtLink class="link" :to="localePath('/c2c')">
              <i class="icon i-local:deal-s1?mask" />
              <span class="text">{{ $t('yiSrOfcmDFQ4S_64vwKmE') }}</span>
              <img src="~/assets/icons/hot-s1.svg" class="w-[35px] ml2" alt="">
            </NuxtLink>
          </li>
          <li class="item">
            <NuxtLink class="link" :to="localePath('/b2c')">
              <i class="icon i-local:energy-s1?mask" />
              <span class="text">{{ $t('syeMRIeBr3TkMUaTmUyEH') }}</span>
            </NuxtLink>
          </li>
          <li v-if="enableExchange" class="item">
            <NuxtLink class="link" :to="localePath('/swap')">
              <i class="icon i-local:swap-s1?mask" />
              <span class="text">{{ $t('t1QZ_e94a6URx3hw3ARVe') }}</span>
            </NuxtLink>
          </li>
          <li class="item" data-test-id="n03e" @click="globalModal.Recommend?.open()">
            <span class="link">
              <i class="icon i-local:praise-s1?mask" />
              <span class="text">{{ $t('s6hGj36jqP0Qyb57_CW9M') }}</span>
            </span>
          </li>
          <li class="item agent">
            <NuxtLink class="link" :to="localePath('/agent')">
              <i class="icon i-local:char-s1?mask" />
              <span class="text">{{ $t('uwy65LAgioLBtO-8l_7nS') }}</span>
            </NuxtLink>
          </li>
          <li class="item agent">
            <NuxtLink class="link" :to="localePath('/blog')">
              <i class="icon i-ph:newspaper-clipping-fill" />
              <span class="text">{{ $t('1J6d94jQiyf2iQ5ynjsR') }}</span>
            </NuxtLink>
          </li>
          <li class="item">
            <a class="link" :href="`/doc/${$t('doc-lang')}`" data-test-id="NT5P">
              <i class="icon i-iconamoon:file-document-fill other bg-[#8098bf]" />
              <span class="text">{{ $t('3p7uB1VlYHERPyaVAlCT7') }}</span>
            </a>
          </li>
          <li class="item">
            <a class="link" :href="tgLink" data-test-id="c34N">
              <i class="icon i-local:service-s1?mask" />
              <span class="text">{{ $t('33AuKcdUY0B9RsFRtbSSz') }}</span></a>
          </li>
        </ul>
        <div v-if="userInfo" class="logout" data-test-id="ikle" @click="handleLogout">{{ $t('jz9d6i6ySneWChWaGl') }}</div>
      </ADrawer>
    </ClientOnly>
    <AModal
      v-model:open="isLogout"
      :footer="null"
      destroyOnClose
      :closable="true"
      :maskClosable="false"
      wrapClassName="ant-cover__basic-modal"
      centered
      :title="$t('mxo0cnGrkxXuDuAchSx')"
    >
      <div class="mx-auto mt-[32px]">
        <p class="text-18px text-text text-center">{{ $t('vgSxF2KG5obiPgZv4sK0') }}</p>
        <div class="flex justify-center items-center mt4 gap4">
          <AButton class="cancel-btn flex-1" data-test-id="jy3k" @click="isLogout = false">{{ $t('hAns2WaHjEPbTgBxlqXn') }}</AButton>
          <AButton class="comfirm-btn flex-1" :loading="loadLogout" data-test-id="khk9" @click="logout()">{{ $t('UENBgObobQIqSj65k6uBN') }}</AButton>
        </div>
      </div>
    </AModal>
  </div>
</template>

<style lang="scss" scoped>
.nav-list {
  .item {
    background: url('@/assets/icons/arrow-s1.svg') no-repeat;
    background-position: calc(100% - 10px) center;

    .text {
      --uno: 'text-[#e2e8f2] text-16px';
    }

    &:hover, &:active {
      .text {
        color: var(--highlight);
      }
    }

    .link {
      --uno: 'flex items-center h-50px rounded-6px p-[0_10px]';
    }

    .icon {
      --uno: 'block mr-0.4em w-1.2em h1.2em text-[#8098bf]';

      &.other {
        --uno: 'bg-bg-icon_light';
      }
    }

    .decorated {
      --uno: 'block ml-0.2em';
    }
  }

  .log {
    --uno: 'mb-16px';

    background: url('@/assets/icons/back-s1.svg') no-repeat;
    background-position: right;

    .link {
      padding: 0;
    }

    .icon {
      --uno: 'text-28px';

    }

    .title {
      --uno: 'ml-5px text-18px font-bold text-[#e2e8f2]';
    }
  }

  .info{
    --uno: 'block items-center pl-12px py-10px text-[#e2e8f2]';

    position: relative;
    font-size: 14px;
    background: linear-gradient(80deg, #2a47ab, #266ef1);
    border-radius: 10px;

    &:hover{
      background: linear-gradient(90deg, rgb(38 109 241) 10%, rgb(7 171 180) 80%);
    }

    &::after{
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 20px;
      content: '';
      background: url('@/assets/icons/arrow-s2.svg') no-repeat calc(100% - 10px) center;
      transform: translateY(-50%);
    }

    >div{
      --uno: 'h-30px flex items-center';
    }
  }

  .login{
    --uno: 'text-center h-40px lh-40px border-rd-10px text-[#e2e8f2] mb-10px';

    a{
      --uno: 'block items-center pl-12px text-[#e2e8f2] text-14px border-rd-10px';

      background: linear-gradient(90deg, rgb(38 110 241) 10%, rgb(7 171 180) 83%);
    }
  }
}

.logout{
  --uno: 'bg-[#202c52] text-[#e2e8f2] border-rd-10px py-14px text-center mt-10px cursor-pointer';

  &:hover{
    box-shadow: 0 2px 0 #000;
  }
}

.router-link-active {
  --uno: 'bg-[var(--mobile-active-bg)]';
}
</style>

<style lang="scss">
.side-nav-drawer {
  .ant-drawer-content-wrapper {
    --uno: '!w-65% min-w-200px max-w-300px';
  }

  .ant-drawer-body {
    --uno: 'p-0';

    background: #1a2342;
  }
}
</style>
